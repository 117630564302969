function generateCorsHeaders () {
  return {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
  }
}

function generateResponse (code, data) {
  return {
    statusCode: code,
    body: JSON.stringify({
      code,
      data
    })
  }
}

module.exports.generateCorsHeaders = generateCorsHeaders;
module.exports.generateResponse = generateResponse;