const response = require("./response")

class ResponseError extends Error {
  constructor(message, code = 500, data = {}) {
    super(message)
    this.code = code
    this.data = data
  }
}

function errorHandler(err) {
  if (err instanceof ResponseError) {
    return response.generateResponse(err.code, {
      error: {
        message: err.message,
        data: err.data,
      },
    })
  }
  return response.generateResponse(500, {
    error: {
      message: err.message,
    },
  })
}

module.exports.ResponseError = ResponseError
module.exports.errorHandler = errorHandler
