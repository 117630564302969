import React, { useCallback, useState } from "react"
import cx from "classnames"
import { useIntl } from "gatsby-plugin-intl"
import ReactMarkdown from "react-markdown"
import { signUp } from "api"
import useReasons from "../useReasons";
import QandrBrandLogo from "../images/qandr-brand-logo.svg";
import Form, { FormValidationError } from "../components/form";
import Layout from "../components/layout";

import SEO from "../components/seo"

const SubmitResult = ({ result, onDismiss }) => {
  const intl = useIntl();
  const isError = result instanceof Error

  return (
    <div className={cx("card", { "bg-warning": isError })}>
      <div className="card-body text-center">{isError ? intl.formatMessage({ id: "form_submit_error_msg" }) : <ReactMarkdown source={result} />}</div>
      { isError && (
          <button href="#" onClick={onDismiss} className="btn btn-link">{intl.formatMessage({ id: "form_submit_error_button" })}</button>
        )
      }

    </div>
  )
}

const IndexPage = ({ location }) => {
  const intl = useIntl()

  const reasons = useReasons();
  const [submitResult, setSubmitResult] = useState(undefined)

  const params = new URLSearchParams(location.search.substring(1));

  const submit = useCallback(
    async data => {
        try {
          console.log("data = ", data);
          await signUp({ ...data, preferredLanguage: intl.locale });
        } catch(err) {
          if(err.response && err.response.status === 409) {
            throw new FormValidationError("conflict", "email");
          }
          throw err;
        }
    },
    [intl]
  );

  return (
    <Layout>
      <SEO title="Sign up" />
      <div className="row h-100">
        <div className="col col-xs-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
          <div className="row justify-content-center mt-3 mb-3">
            <a href="https://www.qandr.eu" title="To QANDR home page"><QandrBrandLogo /></a>
          </div>
          <div className="row justify-content-center mt-4">
            <h1>{intl.formatMessage({ id: "page_title" })}</h1>
          </div>
          <div className="spacing" />
          <div className="row justify-content-center">
            <div className="col">
              {!submitResult ? (
                <>
                <div className="text-center">
                  <span className="d-inline-block text-left"><ReactMarkdown source={intl.formatMessage({ id: "page_text" })} /></span>
                </div>
                <div className="spacing" />
                <Form 
                  submit={submit} 
                  onSubmit={() => {
                    setSubmitResult(intl.formatMessage({ id: "form_submit_success_msg" }))
                  }}
                  onSubmitError={setSubmitResult}
                  prefilledEmail={params.get("email")}
                  messages={intl.messages} 
                  reasons={reasons} 
                />
              </>
              ): <SubmitResult result={submitResult} onDismiss={() => setSubmitResult(undefined)}/> }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
