import { useMemo } from "react";
import { useIntl } from "gatsby-plugin-intl"

const REASONS = {
  "nl": require("./intl/reasons.nl.json"),
  "en": require("./intl/reasons.en.json")
};

const DEFAULT_LOCALE = "en";

const useReasons = () => {
  const { locale } = useIntl();

  const reasons = useMemo(() => {
    return REASONS[locale].map(
      (r, index) => typeof r === 'object' && r !== null ? r : ({
        value: REASONS[DEFAULT_LOCALE][index],
        readable: r
      })
    )
  }, [locale]);

  return reasons;
};

export default useReasons;