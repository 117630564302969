const endpoint = `${process.env.SIGNUP_ENDPOINT}/handle-form`;

class HttpError extends Error {
  constructor(response) {
    super("An HTTP error occurred.");
    this.response = response;
  }
};

export const signUp = async (data) => {
  const args = {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    body: JSON.stringify(data)
  };

  const response = await fetch(endpoint, args);
  if(!response.ok) {
    throw new HttpError(response);
  } else {
    return await response.json();
  }
}
