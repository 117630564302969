import * as actionTypes from "./actionTypes";

export const initialState = Object.freeze({
  submitting: false,
  submitError: undefined,
  submitted: false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBMIT:
      return {
        ...initialState,
        submitted: false,
        submitting: true
      };
    case actionTypes.SUBMIT_ERROR:
      return {
        ...initialState,
        submitting: false,
        submitted: true,
        submitError: action.payload.message
      };
    case actionTypes.SUBMIT_SUCCESS:
      return {
        ...initialState,
        submitted: true
      };
    default:
      return state;
  }
};
