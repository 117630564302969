import React, { useCallback, useReducer } from "react"
import { useForm, useFormContext, FormContext } from "react-hook-form"
import ReactMarkdown from "react-markdown"
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import cx from "classnames"
import { validatePassword, validateEmail } from "../../../backend/src/validation";
import * as actions from "./actions"
import reducer, { initialState } from "./reducer"
import { FormValidationError } from ".";

const InputError = ({ field, messages }) => {
  const { errors, formState } = useFormContext()

  const errorField = errors[field]
    ? `form_${field}_error_${errors[field].type}`
    : null

  return formState.touched[field] && errorField ? (
    <div className="invalid-feedback"><ReactMarkdown source={messages[errorField]} /></div>
  ) : null
}

const Form = ({ messages, reasons, submit, onSubmit, prefilledEmail, onSubmitError }) => {
  const methods = useForm({
    mode: "onBlur",
  })

  const [state, dispatch] = useReducer(reducer, initialState)
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState,
    triggerValidation,
  } = methods

  const { touched } = formState

  const doSubmit = useCallback(
    async data => {
      console.log("DATA = ", data);
      dispatch(actions.submit());
      try{
        const recaptchaToken = await executeRecaptcha("signup_form");
        await submit({ ...data, email: data.email.toLowerCase(), recaptchaToken });
        dispatch(actions.submitSuccess())
        onSubmit("Success");
      } catch(err) {
        dispatch(actions.submitError(err));
        if(err instanceof FormValidationError) {
          setError(err.field, err.type);
        } else {
          onSubmitError(err);
        }
      }
    },
    [executeRecaptcha, submit, onSubmit, setError, onSubmitError]
  )

  const validationClasses = field => ({
    "is-valid": touched[field] && !errors[field],
    "is-invalid": touched[field] && errors[field],
  })

  const validate = useCallback(() => {
    triggerValidation()
  }, [triggerValidation])

  const emailDisabled = state.submitting || (prefilledEmail && prefilledEmail.length > 0);

  return (
    <FormContext {...methods}>
      <form noValidate onSubmit={handleSubmit(doSubmit)}>
        <div className="form-group">
          <label htmlFor="email">{messages["form_email_label"]}</label>
          <input
            className={cx("form-control", validationClasses("email"))}
            id="email"
            name="email"
            defaultValue={prefilledEmail}
            placeholder={messages["form_email_placeholder"]}
            autoComplete="username"
            type="email"
            disabled={emailDisabled}
            required
            ref={register({
              required: true,
              validate: validateEmail,
            })}
          />
          <InputError field="email" messages={messages} />
        </div>
        <div className="form-row">
          <div className="form-group col">
            <label htmlFor="firstName">
              {messages["form_firstName_label"]}
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              autoComplete="given-name"
              className={cx("form-control", validationClasses("firstName"))}
              placeholder={messages["form_firstName_placeholder"]}
              ref={register({ required: true })}
              disabled={state.submitting}
            />
            <InputError field="firstName" messages={messages} />
          </div>
          <div className="form-group col">
            <label htmlFor="lastName">{messages["form_lastName_label"]}</label>
            <input
              type="text"
              id="lastName"
              autoComplete="family-name"
              name="lastName"
              className={cx("form-control", validationClasses("lastName"))}
              placeholder={messages["form_lastName_placeholder"]}
              ref={register({ required: true })}
              disabled={state.submitting}
            />
            <InputError field="lastName" messages={messages} />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="company">{messages["form_company_label"]}</label>
          <input
            className={cx("form-control", validationClasses("company"))}
            autoComplete="organization"
            id="company"
            name="company"
            placeholder={messages["form_company_placeholder"]}
            type="text"
            disabled={state.submitting}
            ref={register()}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">{messages["form_password_label"]}</label>
          <input
            className={cx("form-control", validationClasses("password"))}
            name="password"
            placeholder={messages["form_password_placeholder"]}
            id="password"
            required
            autoComplete="new-password"
            onChange={validate}
            type="password"
            disabled={state.submitting}
            ref={register({
              required: true,
              validate: validatePassword,
            })}
          />
          <InputError field="password" messages={messages} />
          <small id="passwordHelpBlock" className="form-text text-muted">
            {messages["form_password_help"]}
          </small>
        </div>
        <div className="form-group">
          <label htmlFor="usageReason">{messages["form_reason_label"]}</label>
          <select
            id="reason"
            name="reason"
            className="form-control"
            disabled={state.submitting}
            ref={register()}
            defaultValue={-1}
          >
            <option disabled value={-1}>
              {messages["form_reason_placeholder"]}
            </option>
            {reasons.map(({ value, readable }) => (
              <option key={value} value={value}>
                {readable}
              </option>
            ))}
          </select>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            name="newsletter"
            id="newsletter"
            disabled={state.submitting}
            ref={register()}
          />
          <label className="form-check-label" htmlFor="newsletter">
            {messages["form_newsletter_label"]}
          </label>
        </div>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            name="terms"
            id="terms"
            disabled={state.submitting}
            onChange={validate}
            ref={register({
              required: true,
              validate: value => !!value,
            })}
          />
          <label className="form-check-label" htmlFor="exampleCheck1">
            <ReactMarkdown source={messages["form_terms_label"]} />
          </label>
          <InputError field="terms" messages={messages} />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!formState.isValid || state.submitting}
        >
          {state.submitting ? (
            <React.Fragment>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
              &nbsp; {messages["form_loading"]}
            </React.Fragment>
          ) : (
            messages["form_submit_label"]
          )}
        </button>
      </form>
    </FormContext>
  )
}

export default Form
