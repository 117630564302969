const _validate = require("validate.js");
const emailValidator = require("email-validator");
const { ResponseError } = require("./error");

const passwordRegex = /^(?=.*[0-9])(?=.*[a-z]).{8,32}$/;

class ValidationError extends ResponseError {
  constructor(errors) {
    super("A validation error occurred", 422, errors);
  }
}

function validatePassword(value) {
  return !!`${value}`.match(passwordRegex);
};

_validate.validators.password = (value) => {
  const isValid = validatePassword(value);
  if(!isValid) {
    return "is not a secure password";
  }
};

_validate.validators.terms = (value) => {
  if(value !== true) {
    return "have to be accepted";
  }
};

const constraints = {
  email: {
    email: true,
    presence: true
  },
  firstName: {
    presence: true
  },
  lastName: {
    presence: true
  },
  password: {
    presence: true,
    password: true,
  },
  terms: {
    terms: true
  }
}

function validationErrors(obj){
  return _validate(obj, constraints);
}

function validate(obj) {
  const errors = validationErrors(obj);
  if(errors) {
    throw new ValidationError(errors);
  }
}

module.exports.validateEmail = (value) => emailValidator.validate(value);
module.exports.validatePassword = validatePassword;
module.exports.validationErrors = validationErrors;
module.exports.validate = validate;
module.exports.ValidationError = ValidationError;